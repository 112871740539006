import React, { useContext, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import HeaderTopbar from '../HeaderTopbar/HeaderTopbar';
import MobileMenu from '../MobileMenu/MobileMenu';
import Logo from '../../images/logo.png';
import LoginModal from '../common/LoginModal';
import UserContext from '../ContextApi/UserContext';
import { clearLocalData } from '../../utils/CoustomStorage';

const Header = (props) => {
  const { user } = useContext(UserContext);
  const [modal, setModal] = useState(false);
  const [menuActive, setMenuState] = useState(false);
  const push = useNavigate();

  const toggle = () => setModal(!modal);
  const SubmitHandler = (e) => {
    e.preventDefault();
  };

  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };
  const logout = () => {
    // push("/")
    clearLocalData();
    window.location.reload();
  };

  return (
    <header id='header' className={props.topbarNone}>
      <HeaderTopbar />
      <div className={`wpo-site-header ${props.hclass}`}>
        <nav className='navigation navbar navbar-expand-lg navbar-light'>
          <div className='container-fluid'>
            <div className='row align-items-center'>
              <div className='col-lg-3 col-md-3 col-2 d-lg-none dl-block'>
                <div className='mobail-menu'>
                  <MobileMenu />
                </div>
              </div>
              <div className='col-lg-3 col-md-3 col-3'>
                <div className='navbar-header'>
                  <Link onClick={ClickHandler} className='navbar-brand' to='/home'>
                    <img src='img/buzzCardsLogo2.png' style={{ height: '60px', width: '160px' }}></img>
                  </Link>
                </div>
              </div>

              <div className='col-lg-6 col-md-1 col-1'>
                <div id='navbar' className='collapse navbar-collapse navigation-holder'>
                  <button className='menu-close'>
                    <i className='ti-close'></i>
                  </button>
                  <ul className='nav navbar-nav mb-2 mb-lg-0'>
                    <li className='menu-item-has-children'>
                      <Link onClick={ClickHandler} to='/'>
                        Home
                      </Link>
                      {/* <ul className="sub-menu">
                                                <li><Link onClick={ClickHandler} to="/home">Home style 1</Link></li>
                                                <li><Link onClick={ClickHandler} to="/home2">Home style 2</Link></li>
                                                <li><Link onClick={ClickHandler} to="/home3">Home style 3</Link></li>
                                                <li><Link onClick={ClickHandler} to="/home4">Home style 4</Link></li>
                                                <li><Link onClick={ClickHandler} to="/home5">Home style 5</Link></li>
                                                <li><Link onClick={ClickHandler} to="/home6">Home style 6</Link></li>
                                                <li><Link onClick={ClickHandler} to="/home7">Home style 7</Link></li>
                                            </ul> */}
                    </li>
                    <li>
                      <Link onClick={ClickHandler} to='/about'>
                        About Us
                      </Link>
                    </li>
                    <li className='menu-item-has-children'>
                      <Link to='/service'>Services</Link>
                    </li>
                    <li className='menu-item-has-children'>
                      <Link to='/project'>Project</Link>
                    </li>
                    {/* <li className="menu-item-has-children">
                                            <Link to="/project">Pages</Link>
                                            <ul className="sub-menu">
                                                <li><Link onClick={ClickHandler} to="/team">Team</Link></li>
                                                <li><Link onClick={ClickHandler}  to="/team-single/1">Team single</Link></li>
                                                <li><Link onClick={ClickHandler}  to="/shop">Shop Page</Link></li>
                                                <li><Link onClick={ClickHandler}  to="/shop-single">Shop Single</Link></li>
                                                <li><Link onClick={ClickHandler}  to="/cart">Cart</Link></li>
                                                <li><Link onClick={ClickHandler}  to="/checkout">Checkout</Link></li>
                                                <li><Link onClick={ClickHandler}  to="/pricing">Pricing</Link></li>
                                                <li><Link onClick={ClickHandler}  to="/404">404 Error</Link></li>
                                                <li><Link onClick={ClickHandler} to="/login">Login</Link></li>
                                                <li><Link onClick={ClickHandler} to="/register">Register</Link></li>
                                            </ul>
                                        </li> */}

                    <li>
                      <Link onClick={ClickHandler} to='/contact'>
                        Contact
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className='col-lg-3 col-md-2 col-2'>
                <div className='header-right'>
                  {/* <div className="header-search-form-wrapper">
                                        <div className="cart-search-contact">
                                            <button onClick={() => setMenuState(!menuActive)} className="search-toggle-btn"><i
                                                className={`fi ti-search ${menuActive ? "ti-close" : "fi "}`}></i></button>
                                            <div className={`header-search-form ${menuActive ? "header-search-content-toggle" : ""}`}>
                                                <form onSubmit={SubmitHandler}>
                                                    <div>
                                                        <input type="text" className="form-control"
                                                            placeholder="Search here..." />
                                                        <button type="submit"><i
                                                            className="fi flaticon-search-interface-symbol"></i></button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div> */}
                  <div className='close-form'>
                    {user?.role == 'user' ? (
                      <Link style={{ background: 'gray' }} className='theme-btn' to={'/apply'}>
                        Go To Application
                      </Link>
                    ) : (
                      <Link to={'/apply-guest'} className='theme-btn' style={{ background: 'gray' }}>
                        Apply Now
                      </Link>
                    )}
                  </div>
                  <div className='close-form'>
                    {user?.role == 'user' ? (
                      <Link onClick={logout} style={{ background: 'blue' }} className='theme-btn'>
                        Logout
                      </Link>
                    ) : (
                      <Link onClick={toggle} style={{ background: 'blue' }} className='theme-btn'>
                        Login
                      </Link>
                    )}
                  </div>
                </div>
              </div>
              <div className='col-md-3 col-4 navCustomBtnLoginLogout'>
                {user?.role == 'user' ? (
                  // <Link className="btn10" to={'/apply'}>Go To Application</Link>
                  <></>
                ) : (
                  <Link to={'/apply-guest'} style={{ marginRight: '5px', background: '#ed3400' }} className='btn10'>
                    Apply
                  </Link>
                )}
                {user?.role == 'user' ? (
                  <Link onClick={logout} className='btn10'>
                    Logout
                  </Link>
                ) : (
                  <Link onClick={toggle} className='btn10'>
                    Login
                  </Link>
                )}
              </div>
            </div>
          </div>
        </nav>
      </div>
      <LoginModal modal={modal} toggle={toggle} />
    </header>
  );
};

export default Header;
