import React from 'react';
import simg from '../../images/mission-shape.png';

const MissionVission = (props) => {
  return (
    <section className='wpo-mission-vission-section section-padding'>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-6 col-md-12 col-12'>
            <div className='wpo-mission-item'>
              <div className='wpo-mission-text'>
                <h2>Our Mission</h2>
                <p>
                  Our mission at RingsCard is to empower individuals and businesses with innovative banking
                  solutions that enhance financial well-being and promote economic growth. We strive to deliver
                  excellence in customer service, foster trust and transparency, and contribute positively to the
                  communities we serve.
                </p>
                <h5>Discover a wide range of personal banking services designed to meet your unique needs. </h5>
              </div>
              <div className='shape-1'></div>
              <div className='shape-2'>
                <img src={simg} alt='' />
              </div>
            </div>
          </div>
          <div className='col-lg-6 col-md-12 col-12'>
            <div className='wpo-vission-item'>
              <div className='wpo-vission-text'>
                <h2>Our Vission</h2>
                <h5>
                  From savings and checking accounts to personal loans and mortgages, RingsCard offers flexible and
                  convenient solutions to help you manage your finances with ease.
                </h5>
                <p>
                  At RingsCard, we are dedicated to revolutionizing the banking experience. Our team comprises
                  industry experts with years of experience in finance, technology, and customer service. We believe in
                  harnessing the power of technology to simplify banking processes while prioritizing security and
                  efficiency.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default MissionVission;
