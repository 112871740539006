import React, { useEffect, useState } from 'react'
import SimpleReactValidator from 'simple-react-validator';
import API from '../../utils/apiCalling';
import { config } from '../../utils/apiUrl';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import useGetProfileDetails from '../../hooks/useProfileDetails';
import Swal from 'sweetalert2';


const IdentityForm = ({steperChange}) => {
    const { getUserProfile } = useGetProfileDetails()
    const push = useNavigate()
    const api = new API()
    const [forms, setForms] = useState({
        aadhar_number: '',
        pancard_number: '',
        aadhar_back_img: '',
        aadhar_front_img: '',
        pan_card_img: '',
        passport_size_photo: '',
    });
    const [validator] = useState(new SimpleReactValidator({
        className: 'errorMessage'
    }));
    useEffect(()=>{
        window.scrollTo({
            top: 0,
            behavior: 'smooth' // Optional, adds smooth scrolling behavior
          });
    },[])
    const changeHandler = e => {
        setForms({ ...forms, [e.target.name]: e.target.value })
        if (validator.allValid()) {
            validator.hideMessages();
        } else {
            validator.showMessages();
        }
    };
    const changeHandlerImg = e => {
        console.log(e.target.files)
        setForms({ ...forms, [e.target.name]: e.target.files[0] })
        // if (validator.allValid()) {
        //     validator.hideMessages();
        // } else {
        //     validator.showMessages();
        // }
    };

    const submitHandler = async(e) => {
        e.preventDefault();
        if (validator.allValid()) {
            validator.hideMessages();
            // let data = {
            //     aadhar_number: forms.aadhar_number,
            //     pancard_number: forms.pancard_number,
            //     aadhar_back_img: forms.aadhar_back_img,
            //     aadhar_front_img: forms.aadhar_front_img,
            //     pan_card_img: forms.pan_card_img,
            //     passport_size_photo: forms.passport_size_photo,
            //     isIdentityInformation: true,
            // }
            const formData = new FormData();
            formData.append('aadhar_number', forms.aadhar_number);
            formData.append('pancard_number', forms.pancard_number);
            formData.append('aadhar_back_img', forms.aadhar_back_img);
            formData.append('aadhar_front_img', forms.aadhar_front_img);
            formData.append('pan_card_img', forms.pan_card_img);
            formData.append('passport_size_photo', forms.passport_size_photo);
            formData.append('isIdentityInformation', true)
            Swal.fire({
                title: "Are you sure?",
                text: "Are you sure to save identity details!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes!"
              }).then(async(result) => {
                if (result.isConfirmed) {

            const response = await api.postUpload(config.updateDetail, formData)
            console.log("response is", response)
            if (response.code == 200) {
                steperChange(0)
                Swal.fire({
                    title: "Saved!",
                    text: "Successfully saved identity details !",
                    icon: "success"
                  });
                getUserProfile()
            } else {
                toast.success('Something wen wrong !');
            }
        }
    });
        } else {
            validator.showMessages();
        }
    };

    return (
        <form onSubmit={(e) => submitHandler(e)} className="contact-validation-active" >
            <h2 align="center" style={{color: 'white', marginBottom: '20px'}}>IDENTITY DETAILS</h2>
            <div className="row">
                <div className="col col-lg-6 col-12">
                    <div className="form-field">
                        <input
                            value={forms.aadhar_number}
                            type="text"
                            name="aadhar_number"
                            onBlur={(e) => changeHandler(e)}
                            onChange={(e) => changeHandler(e)}
                            placeholder="Aadhar Number"
                            style={{borderRadius: '30px', color:'#fff', fontSize: '17px', outline:'none'}}
                             />
                        {validator.message('aadhar_number', forms.aadhar_number, 'required|aadhar_number')}
                    </div>
                </div>
                <div className="col col-lg-6 col-12">
                    <div className="form-field">
                        <input
                            value={forms.pancard_number}
                            type="text"
                            name="pancard_number"
                            onBlur={(e) => changeHandler(e)}
                            onChange={(e) => changeHandler(e)}
                            placeholder="Pan Card Number"
                            style={{borderRadius: '30px', color:'#fff', fontSize: '17px', outline:'none'}}
                             />
                        {/* {validator.message('pancard_number', forms.pancard_number, 'required|email')} */}
                    </div>
                </div>
                <div className="col col-lg-6 col-12">
                <label className='mb-2 text-white'>Aadhar front</label>
                    <div className="form-field">
                        <input
                            // value={forms.aadhar_front_img}
                            type="file"
                            name="aadhar_front_img"
                            onBlur={(e) => changeHandlerImg(e)}
                            onChange={(e) => changeHandlerImg(e)} 
                            />
                        {/* {validator.message('aadhar_front_img', forms.aadhar_front_img, 'required|aadhar_front_img')} */}
                    </div>
                </div>
                <div className="col col-lg-6 col-12">
                    <label className='mb-2 text-white'>Aadhar back</label>
                    <div className="form-field">
                        <input
                            // value={forms.aadhar_back_img}
                            type="file"
                            name="aadhar_front_img"
                            onBlur={(e) => changeHandlerImg(e)}
                            onChange={(e) => changeHandlerImg(e)} 
                            />
                        {/* {validator.message('aadhar_back_img', forms.aadhar_back_img, 'required|aadhar_front_img')} */}
                    </div>
                </div>
                <div className="col col-lg-6 col-12">
                    <label className='mb-2 text-white'>Pan card</label>
                    <div className="form-field">
                        <input
                            // value={forms.pan_card_img}
                            type="file"
                            name="pan_card_img"
                            onBlur={(e) => changeHandlerImg(e)}
                            onChange={(e) => changeHandlerImg(e)} 
                            />
                        {/* {validator.message('pan_card_img', forms.pan_card_img, 'required|pan_card_img')} */}
                    </div>
                </div>
                <div className="col col-lg-6 col-12">
                    <label className='mb-2 text-whiteh'>Passport size photo</label>
                    <div className="form-field">
                        <input
                            // value={forms.passport_size_photo}
                            type="file"
                            name="passport_size_photo"
                            onBlur={(e) => changeHandlerImg(e)}
                            onChange={(e) => changeHandlerImg(e)} 
                            />
                        {/* {validator.message('passport_size_photo', forms.passport_size_photo, 'required|passport_size_photo')} */}
                    </div>
                </div>

            </div>
            <div className="submit-area">
                <button type="submit" className="theme-btn">Apply</button>
            </div>
        </form >
    )
}

export default IdentityForm;
