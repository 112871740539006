import React, { useState } from 'react';
import { CCollapse, CButton, CCardBody, CBadge, CDataTable } from '@coreui/react';
// import { CDataTable  } from '@coreui/react/core';
// import '@coreui/coreui/dist/css/coreui.min.css'
import moment from 'moment';
import UserDetailsUpdateForm from './common/userUpdateForm';
import { Step, Stepper } from 'react-form-stepper';
import { imgUrl } from '../../config/env';
import ResponsivePagination from 'react-responsive-pagination';
import 'react-responsive-pagination/themes/classic.css';
const CryptoJS = require('crypto-js');

// const plaintext = 'hello';

// // Encryption
// const encrypted = CryptoJS.AES.encrypt(plaintext, 'secret passphrase').toString();

// console.log('Encrypted:', encrypted);

// Decryption
// const decrypted = CryptoJS.AES.decrypt(encrypted, 'secret passphrase').toString(CryptoJS.enc.Utf8);

// console.log('Decrypted:', decrypted);

const DataTable = ({ allUserDetails, updateUserDetails, deleteUser, setPage, setPageSize, setSearch, search }) => {
  const [approvedLimit, setApprovedLimit] = useState(0);

  const usersData = [
    { id: 0, name: 'John Doe', registered: '2018/01/01', role: 'Guest', status: 'Pending' },
    { id: 1, name: 'Samppa Nori', registered: '2018/01/01', role: 'Member', status: 'Active' },
    { id: 2, name: 'Estavan Lykos', registered: '2018/02/01', role: 'Staff', status: 'Banned' },
    { id: 3, name: 'Chetan Mohamed', registered: '2018/02/01', role: 'Admin', status: 'Inactive' },
    { id: 4, name: 'Derick Maximinus', registered: '2018/03/01', role: 'Member', status: 'Pending' },
    { id: 5, name: 'Friderik Dávid', registered: '2018/01/21', role: 'Staff', status: 'Active' },
    { id: 6, name: 'Yiorgos Avraamu', registered: '2018/01/01', role: 'Member', status: 'Active' },
    { id: 7, name: 'Avram Tarasios', registered: '2018/02/01', role: 'Staff', status: 'Banned' },
    { id: 8, name: 'Quintin Ed', registered: '2018/02/01', role: 'Admin', status: 'Inactive' },
    { id: 9, name: 'Enéas Kwadwo', registered: '2018/03/01', role: 'Member', status: 'Pending' },
    { id: 10, name: 'Agapetus Tadeáš', registered: '2018/01/21', role: 'Staff', status: 'Active' },
    { id: 11, name: 'Carwyn Fachtna', registered: '2018/01/01', role: 'Member', status: 'Active' },
    { id: 12, name: 'Nehemiah Tatius', registered: '2018/02/01', role: 'Staff', status: 'Banned' },
    { id: 13, name: 'Ebbe Gemariah', registered: '2018/02/01', role: 'Admin', status: 'Inactive' },
    { id: 14, name: 'Eustorgios Amulius', registered: '2018/03/01', role: 'Member', status: 'Pending' },
    { id: 15, name: 'Leopold Gáspár', registered: '2018/01/21', role: 'Staff', status: 'Active' },
    { id: 16, name: 'Pompeius René', registered: '2018/01/01', role: 'Member', status: 'Active' },
    { id: 17, name: 'Paĉjo Jadon', registered: '2018/02/01', role: 'Staff', status: 'Banned' },
    { id: 18, name: 'Micheal Mercurius', registered: '2018/02/01', role: 'Admin', status: 'Inactive' },
    { id: 19, name: 'Ganesha Dubhghall', registered: '2018/03/01', role: 'Member', status: 'Pending' },
    { id: 20, name: 'Hiroto Šimun', registered: '2018/01/21', role: 'Staff', status: 'Active' },
    { id: 21, name: 'Vishnu Serghei', registered: '2018/01/01', role: 'Member', status: 'Active' },
    { id: 22, name: 'Zbyněk Phoibos', registered: '2018/02/01', role: 'Staff', status: 'Banned' },
    { id: 23, name: 'Aulus Agmundr', registered: '2018/01/01', role: 'Member', status: 'Pending' },
    { id: 42, name: 'Ford Prefect', registered: '2001/05/25', role: 'Alien', status: "Don't panic!" },
  ];

  const [details, setDetails] = useState([]);
  // const [items, setItems] = useState(usersData)

  const toggleDetails = (index) => {
    const position = details.indexOf(index);
    let newDetails = details.slice();
    if (position !== -1) {
      newDetails.splice(position, 1);
    } else {
      newDetails = [...details, index];
    }
    setDetails(newDetails);
  };

  const fields = [
    { key: 'id', label: 'ID', _style: { width: '20%' } },
    { key: 'email', label: 'Email', _style: { width: '20%' } },
    { key: 'mobile', label: 'Mobile', _style: { width: '10%' } },
    { key: 'name', label: 'Name', _style: { width: '20%' } },
    { key: 'netMonthlyIncome', label: 'Salary', _style: { width: '20%' } },
    { key: 'status', label: 'Status', _style: { width: '10%' } },
    // { key: 'applyFor', label: 'Apply For', _style: { width: '10%'}},
    { key: 'createdAt', label: 'Created Date', _style: { width: '20%' } },
    {
      key: 'show_details',
      label: '',
      _style: { width: '1%' },
      sorter: false,
      filter: false,
    },
  ];

  const getBadge = (status) => {
    switch (status) {
      case 'active':
        return 'blue';
      case 'inactive':
        return 'grey';
      case 'delete':
        return 'red';
      case 'danger':
        return 'red';
      default:
        return 'primary';
    }
  };
  const startIndex = (allUserDetails?.page - 1) * (allUserDetails?.limit) + 1;
  return (
    <div>
      <CDataTable
        items={allUserDetails?.docs?.map((val,index) => {
          return { ...val, createdAt: moment(val.createdAt).format('DD MMM YYYY HH:mm:ss'), id: startIndex+index };
        })}
        fields={fields}
        // columnFilter
        tableFilter
        tableFilterValue={search}
        footer
        onTableFilterChange={(e) => {
          setSearch(e);
          setPage(1);
        }}
        // itemsPerPageSelect
        // itemsPerPage={20}
        hover
        sorter
        // pagination
        scopedSlots={{
          status: (item) => (
            <td>
              <CBadge style={{ color: getBadge(item.status) }} color={getBadge(item.status)}>
                {item.status}
              </CBadge>
            </td>
          ),
          show_details: (item, index) => {
            return (
              <>
                <td className='py-2'>
                  <CButton
                    color='primary'
                    variant='outline'
                    shape='square'
                    size='sm'
                    onClick={() => {
                      toggleDetails(index);
                    }}
                  >
                    {details.includes(index) ? 'Hide' : 'Show'}
                  </CButton>
                </td>
                <td>
                  <CButton color='danger' variant='outline' shape='square' size='sm' onClick={() => deleteUser(item)}>
                    Delete
                  </CButton>
                </td>
              </>
            );
          },
          details: (item, index) => {
            return (
              <CCollapse show={details.includes(index)}>
                <CCardBody style={{ marginBottom: '20px' }}>
                  <h4>{item.username}</h4>

                  <p className='text-muted'>User since: {item.createdAt}</p>
                  <h4 align='center' style={{ margin: '10px 0px' }}>
                    USER DETAILS
                  </h4>
                  <Stepper
                    activeStep={
                      item?.userDetail?.isIdentityInformation ? 2 : item?.userDetail?.isPersonalInformation ? 1 : 0
                    }
                  >
                    <Step label='Personal Details'></Step>
                    <Step label='Identity Details'></Step>
                  </Stepper>
                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '20px' }}>
                    <h5>Approved Limit: </h5>
                    <input
                      type='number'
                      placeholder={item?.userDetail?.approvedLimit}
                      onChange={(e) => setApprovedLimit(e.target.value)}
                    />
                    <button type='button' onClick={() => updateUserDetails(item, 'approvedLimit', approvedLimit)}>
                      Update
                    </button>
                  </div>
                  <div
                    style={{
                      display: 'grid',
                      gridTemplateColumns: 'repeat(4, 1fr)',
                      gap: '10px',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <UserDetailsUpdateForm
                      item={item}
                      updateUserDetails={updateUserDetails}
                      isPaid={item?.userDetail?.isActivationFeePaid}
                      isFeePaidFor={'isActivationFeePaid'}
                      label='Activation Fee'
                      limit={item?.userDetail?.activationFee}
                      amountFor={'activationFee'}
                    />
                    {/* <UserDetailsUpdateForm item={item} updateUserDetails={updateUserDetails} isPaid={item.userDetail.isAnualFeePaid} isFeePaidFor={'isAnualFeePaid'} label='Anual Fee' limit={item.userDetail.anualFee} amountFor={'anualFee'}/> */}
                    <UserDetailsUpdateForm
                      item={item}
                      updateUserDetails={updateUserDetails}
                      isPaid={item?.userDetail?.isInsuranceFeePaid}
                      isFeePaidFor={'isInsuranceFeePaid'}
                      label='Insurance Fee'
                      limit={item?.userDetail?.insuranceFee}
                      amountFor={'insuranceFee'}
                    />
                    <UserDetailsUpdateForm
                      item={item}
                      updateUserDetails={updateUserDetails}
                      isPaid={item?.userDetail?.isGstFeePaid}
                      isFeePaidFor={'isGstFeePaid'}
                      label='GST Fee'
                      limit={item?.userDetail?.gstFee}
                      amountFor={'gstFee'}
                    />
                    <UserDetailsUpdateForm
                      item={item}
                      updateUserDetails={updateUserDetails}
                      isPaid={item?.userDetail?.isFdFeePaid}
                      isFeePaidFor={'isFdFeePaid'}
                      label='FD Fee'
                      limit={item?.userDetail?.fdFee}
                      amountFor={'fdFee'}
                    />
                    <UserDetailsUpdateForm
                      item={item}
                      updateUserDetails={updateUserDetails}
                      isPaid={item?.userDetail?.isWaletUpdateFeePaid}
                      isFeePaidFor={'isWaletUpdateFeePaid'}
                      label='Wallet Update Fee'
                      limit={item?.userDetail?.waletUpdate}
                      amountFor={'waletUpdate'}
                    />
                    <UserDetailsUpdateForm
                      item={item}
                      updateUserDetails={updateUserDetails}
                      isPaid={item?.userDetail?.isMemberShipFeePaid}
                      isFeePaidFor={'isMemberShipFeePaid'}
                      label='Membership Fee'
                      limit={item?.userDetail?.memberShipFee}
                      amountFor={'memberShipFee'}
                    />
                    <UserDetailsUpdateForm
                      item={item}
                      updateUserDetails={updateUserDetails}
                      isPaid={item?.userDetail?.isChamberShipFeePaid}
                      isFeePaidFor={'isChamberShipFeePaid'}
                      label='Chambership Fee'
                      limit={item?.userDetail?.chambershipFee}
                      amountFor={'chambershipFee'}
                    />
                    <UserDetailsUpdateForm
                      item={item}
                      updateUserDetails={updateUserDetails}
                      isPaid={item?.userDetail?.isOtherFeePaid}
                      isFeePaidFor={'isOtherFeePaid'}
                      label='Other Fee'
                      limit={item?.userDetail?.otherFee}
                      amountFor={'otherFee'}
                    />
                  </div>
                  <h4 align='center' style={{ margin: '10px 0px' }}>
                    PERSONAL DETAILS
                  </h4>
                  <table class='table table-dark'>
                    <thead>
                      <tr>
                        <th scope='col'>Father Name</th>
                        <th scope='col'>DOB</th>
                        <th scope='col'>Employee Type</th>
                        <th scope='col'>Gender</th>
                        <th scope='col'>PIN Code</th>
                        <th scope='col'>Address</th>
                        <th scope='col'>Aadhar Number</th>
                        <th scope='col'>PAN Number</th>
                        <th scope='col'>Key</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th scope='col'>{item?.userDetail?.father_name}</th>
                        <th>{item?.userDetail?.dob}</th>
                        <th>{item?.userDetail?.employmentType}</th>
                        <th>{item?.userDetail?.gender}</th>
                        <th>{item?.userDetail?.pinCode}</th>
                        <th>{item?.userDetail?.address}</th>
                        <th>{item?.userDetail?.aadhar_number}</th>
                        <th>{item?.userDetail?.pancard_number}</th>
                        <th>
                          {item?.key && CryptoJS?.AES?.decrypt(item?.key, 'mySecretKey')?.toString(CryptoJS?.enc?.Utf8)}
                        </th>
                      </tr>
                    </tbody>
                  </table>

                  <h4 align='center' style={{ margin: '10px 0px' }}>
                    Bank Details
                  </h4>
                  <table class='table table-dark'>
                    <thead>
                      <tr>
                        <th scope='col'>Account holder name</th>
                        <th scope='col'>Acc number</th>
                        <th scope='col'>Amount</th>
                        <th scope='col'>IFSC</th>
                        <th scope='col'>Bank Name</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th scope='col'>{item?.userDetail?.accName}</th>
                        <th>{item?.userDetail?.accountNumber}</th>
                        <th>{item?.userDetail?.amount}</th>
                        <th>{item?.userDetail?.ifsc}</th>
                        <th>{item?.userDetail?.bankName}</th>
                      </tr>
                    </tbody>
                  </table>
                  <div>
                    <h4 align='center' style={{ margin: '10px 0px' }}>
                      PERSONAL DETAILS
                    </h4>
                    <div style={{ display: 'flex', justifyContent: 'center', gap: '50px' }}>
                      <div style={{ textAlign: 'center' }}>
                        <img
                          src={
                            item?.userDetail?.aadhar_front_img
                              ? `${imgUrl}/${item.userDetail.aadhar_front_img}`
                              : 'images/no_img.png'
                          }
                          alt='Aadhar Front'
                          style={{ width: '100px', height: '80px' }}
                        />
                        <h6>Aadhar Card Front</h6>
                      </div>
                      <div style={{ textAlign: 'center' }}>
                        <img
                          src={
                            item?.userDetail?.aadhar_back_img
                              ? `${imgUrl}/${item.userDetail.aadhar_back_img}`
                              : 'images/no_img.png'
                          }
                          alt='Aadhar Back'
                          style={{ width: '100px', height: '80px' }}
                        />
                        <h6>Aadhar Card Back</h6>
                      </div>
                      <div style={{ textAlign: 'center' }}>
                        <img
                          src={
                            item?.userDetail?.pan_card_img
                              ? `${imgUrl}/${item.userDetail.pan_card_img}`
                              : 'images/no_img.png'
                          }
                          alt='PANCARD'
                          style={{ width: '100px', height: '80px' }}
                        />
                        <h6>PANCARD</h6>
                      </div>
                      <div style={{ textAlign: 'center' }}>
                        <img
                          src={
                            item?.userDetail?.passport_size_photo
                              ? `${imgUrl}/${item.userDetail.passport_size_photo}` || '/images/no_img.png'
                              : 'images/no_img.png'
                          }
                          alt='Photo'
                          style={{ width: '100px', height: '80px' }}
                        />
                        <h6>Photo</h6>
                      </div>
                    </div>
                  </div>

                  <div
                    style={{
                      height: '100px',
                      gap: '20px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    {/* <CButton onClick={()=>deleteUser(item)} style={{marginRight: '10px'}} size="sm" color="info">
                    Delete User
                  </CButton> */}
                    <CButton
                      onClick={() =>
                        updateUserDetails(item, 'status', item?.userDetail?.status == 'active' ? 'inactive' : 'active')
                      }
                      style={{ marginRight: '10px' }}
                      size='sm'
                      color='info'
                    >
                      {item?.status == 'active' ? 'Active' : 'InActive'}
                    </CButton>
                  </div>
                </CCardBody>
              </CCollapse>
            );
          },
        }}
      />
      <ResponsivePagination
        current={allUserDetails?.page}
        total={Math.floor(allUserDetails?.totalDocs / allUserDetails.limit)}
        onPageChange={(page) => {
          setPage(page);
          setSearch('');
        }}
      />
    </div>
  );
};
export default DataTable;
